import React from 'react'
import Header from '../Helper/Header'

function Contact() {
  return (
    <>
     <Header/>
     <div>Contact</div>
    </>
  )
}

export default Contact